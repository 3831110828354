import { useEffect, useState } from 'react';
import type { Order } from '@wilm/shared-types/cart/Order';
import { useCart } from 'frontastic';

const useOrderFetch = () => {
    const { orderHistory, shippingMethods } = useCart();
    const [orders, setOrders] = useState<Order[]>([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        if (orderHistory) {
            setLoading(true);

            orderHistory()
                .then(data => {
                    setOrders(data);
                    setLoading(false);
                })
                .catch(() => {
                    setOrders([]);
                    setLoading(false);
                });
        }
    }, [orderHistory]);

    return {
        orders,
        shippingMethods,
        loading
    };
};

export default useOrderFetch;
